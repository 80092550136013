.cursor-pointer{
  cursor: pointer;
}

.seletectedIndexBackground{
  transition:  .2s all ease-in;
  font-weight: 600;
  color:  #720C20 !important;
  border: 2px solid #720C20;
}


.theme-btn{
  color: white;
  border: none;
  background-color: #820024;
}

.congratulationNew{
   color: #D79B48;
   font-family: Georgia, 'Times New Roman', Times, serif;
  }
  .congratulationNew1{
    font-family: Georgia, 'Times New Roman', Times, serif;
   }

.HeaderHeight{
  height: 90px;
}

.footerHeight{
  height:70px;
}


.overLayLoader{
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  background-color: rgb(0, 0, 0, 0.2);
  z-index: 10000;
}

.labelImage{
  border-radius: 15px;
  height: 105.827px;
  width: 336.378 px;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);

}


