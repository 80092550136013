.label-name-color{
    color: #720C20;
}

.reg{
    background-color: #720C20;
    border-color: #720C20;
    display: flex;
    justify-content: center;
    text-align: center;
    width: 181px;
    height: 46px !important;
    border: none;
}

.reg2btn{
  background-color: #720C20;
  border-color: #720C20;
  display: flex;
  justify-content: center;
  text-align: center;
  /* width: 181px; */
  height: 2rem !important;
  border: none;
}

.db{

    justify-content: flex-end;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


/* Firefox */

input[type=number] {
  -moz-appearance: textfield;
}
.chek{
width: 99%;
margin-top: 8px;
}
.dot{
  margin-right: 0.5%;
}
.Registration-main-container{
  height: calc(100vh - 140px);
  overflow: auto !important;
 } 
 @media (max-width: 570px) {
  .Registrationbtmmobile1{
    font-size: 15px !important;
    padding: 0px !important;
    width: 100px !important;
     height: 40px !important;
  
  }
 
  .Registrationbtmmobile2{
     
    font-size: 15px !important;
    padding: 0px !important;
    width: 100px !important;
     height: 40px !important;
   
  }
 
}
#uniqueDropdown option:first-of-type {
  display: none;
}