/* .ClassReg-Tag-h4{
    font-weight: 400;;
}
.userDetail-Row{
    border-right: 1.3px solid rgb(180, 176, 176);
    border-bottom: 1.3px solid rgb(180, 176, 176);
}
.custmerDetail-Row{
    border-right: 1.3px solid rgb(180, 176, 176);

}
.userName-tag-h6{
    font-weight: 500;
    color: #820024;
}
.userName-tag-span{
    font-weight: 600;
    color: #252525;
    opacity: 50%;
}
.classRoomName-tag-h6{
    font-weight: 500;
    color: #820024;
}
.classRoomName-tag-span{
    font-weight: 600;
    color: #252525;
    opacity: 50%;
}
.Back-Button-Payment{
    border: 1px solid #720C20;
    color: #720C20;
    width: 105px;
    height: 36px;
    font-weight: 500;
}
.Card-Div{
    border: 1px solid red;
    height: 135px;
    width: 130px!important;
}
.Card-text{
 
 font-size: 14px;
 font-weight:bold;
 
}
.text-center-cash{
    margin-top: 14px;
}
.Back-Button-Row{
    display: none !important;
}

@media (max-width:767px){
    .Payment-Card-Col{
        border:1px solid red !important;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        padding: 0px !important;

    }
    .MakePayment-Col{
        margin-top: 20px !important;
        margin-bottom: 10px !important;
    }
    .userDetail-Row{
        border-right: none;
    }
    .custmerDetail-Row{
        border-right: none;
    }

    .Back-Button-Div{
        display: none;
    }
    .Back-Button-Row{
        display: block !important;
    }
    
}

@media (max-width:600px){
    .Back-Button-Div{
        display: none;
    }
    .Back-Button-Row{
        display: block !important;
    }
}
 */


 /* .confirm-booking-div{
    padding-left: 10px;
    border: 1px solid red;   
} */

.class-room-venu-payment{
    height: calc(100vh - 140px) !important;
    overflow: auto !important;
   }
.user-Details-Div{
    /* border: 1px solid black; */
    /* margin-left: 10px; */
    margin-right: 20px;
    padding-left: 20px;
    margin-right: 50px;
    margin-top: 20px;
}
.Down-line{
    font-size: 1px;
    border: 1px solid black;
    /* border-bottom: 1px dotted black; */
    width: 80%;
    
}


.user-Details-Div span{
    color: gray;
    font-weight: 500;
}
.card-component-div{
    margin-top: 10px;
    width: 350px;
    height: 200px;
    
}
 .confirm-booking-div button{
    background-color: #A23C33;
    color: white;
}  
footer button {
    position: absolute;
    width: 240px;
    height: 52px;
    left: 620px;
    background: #A23C33;
    border-radius: 4px;
    color: rgba(255, 255, 255, 1);
}
.main-payment-div{
    /* border:"1px solid red",paddingLeft:"30px",paddingRight:"30px",paddingTop:"5px" */
    border-left: 1px solid rgb(88, 86, 86);
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 5px;
}
.dt { color: #A23C33;
    font-weight: bold;
}

.vanu-counter{ font-size: 10px;}

 @media (max-width: 600px) {
     .confirm-booking-div{
         display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 7px;
    } 
    .confirm-booking-div button{
        
          width: 100%;
          /* margin-left: 15px; */
          
    }
    
} 

@media (max-width: 800px) {
    .confirm-booking-div{
        display: flex;
       justify-content: center;
       align-items: center;
       padding-left: 7px;
   } 
   .confirm-booking-div button{
       
         width: 100%;
         /* margin-left: 15px; */
         
   }
   
}
