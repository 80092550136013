.Dashboard-container {
    width: 40%;
    margin-left: 30%;
    /* margin-top: 6%; */
    padding-bottom: 5%;

}

.Dashboard-btn {
    width: 285px;
    height: 200px;
    background-color: #A23C33;
    border-style: none;
    border-radius: 10px;
}

.Dashboard-btn :hover {
    color: #D79B48;
}

.Dashboard-text {
    color: white;
    font-size: 'Open Sans', sans-serif;
}


.Proceed-btn{
    color: #720C20;
    font-weight: 700;
    width: 200px;
    height: 46px;
    font-size: 18px;



}

.Proceed-btn:hover {
    background-color: white;
    color: #720C20;
}
.pastseminar{
    background-color: transparent;
    padding: 3px;
    margin: 5px;
    border: none;
    border-radius: 5px;
}

.Dashboard-main-container{
    height: calc(100vh - 180px) !important;
    overflow: auto !important;
}
@media (max-width:600px) {
    .Proceed-btn {
        font-weight: 700;
        width: 130px;
        height: 40px;
        font-size: 12px;
    }
    .proceedSeminarTagH3{
        font-size: 20px;
    }
}
.past{
    color: #720C20;
    font-size: bold;
}
.upcoming{
    color: black;
}
.toggleClr{
    background-color: #720C20;
}
.form-switch .form-check-input {
    height: 20px;
    width: 45px;
}
.form-switch .form-check-input:focus {
    border-color: #720c20;
    outline: 0;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(114, 12, 32, 1)'/></svg>") !important;
}
.form-switch .form-check-input:checked {
    background-color: #720c20;
    border-color: #720c20;
    border: none;
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgb(240, 240, 240)'/></svg>") !important;
}
