/* .confirm-booking-div{
    padding-left: 10px;
    border: 1px solid red;   
} */

.user-Details-Div{
    /* border: 1px solid black; */
    /* margin-left: 10px; */
    margin-right: 20px;
    /* padding-left: 20px; */
    margin-right: 50px;
    margin-top: 20px;
}
.Down-line{
    font-size: 1px;
    border: 1px solid black;
    /* border-bottom: 1px dotted black; */
    width: 80%;
    
}


.user-Details-Div span{
    color: gray;
    font-weight: 500;
}
.card-component-div{
    margin-top: 10px;
    width: 350px;
    height: 200px;
    
}
 .confirm-booking-div button{
    background-color: #A23C33;
    color: white;
}  
footer button {
    position: absolute;
    width: 240px;
    height: 52px;
    left: 620px;
    background: #A23C33;
    border-radius: 4px;
    color: rgba(255, 255, 255, 1);
}
.main-payment-div{
    /* border:"1px solid red",paddingLeft:"30px",paddingRight:"30px",paddingTop:"5px" */
    border-left: 1px solid rgb(88, 86, 86);
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 5px;
}
.dt { color: #A23C33;
    font-weight: bold;
}

.vanu-counter{ font-size: 10px;}

.confirmationBoooking-main-div{
    height: calc(100vh - 140px) !important;
     overflow: auto !important;
  }

  

 @media (max-width: 600px) {
     .confirm-booking-div{
         display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 7px;
    } 
    .confirm-booking-div button{
        
          width: 100%;
          /* margin-left: 15px; */
          
    }
    
} 

@media (max-width: 800px) {
    .confirm-booking-div{
        display: flex;
       justify-content: center;
       align-items: center;
       padding-left: 7px;
   } 
   .confirm-booking-div button{
       
         width: 100%;
         /* margin-left: 15px; */
         
   }
   
}
@media (max-width: 800px) {
    .confirm-booking-div{
        display: flex;
       justify-content: center;
       align-items: center;
       padding-left: 7px;
   } 
   .confirm-booking-div button{
       
         width: 100%;
         /* margin-left: 15px; */
         
   }
   
}
.newButton{
    background-color: #68585f !important;
    color: white !important;
    border: none !important;
}
.Buttonfocus{
     background-color:#820035  !important;
     border: 1px solid #820035 !important ;
    /* box-shadow: #820030  0px 2px 4px, #820030  0px 2px 4px !important; */
}
.newButtonDeposite{
    background-color: #68585f !important;
    color: white !important;
    border: none !important;
}
.ButtonfocusDeposite{
     background-color:#820035  !important;
     border: 1px solid #820035 !important ;
    /* box-shadow: #820030  0px 2px 4px, #820030  0px 2px 4px !important; */
}
.btn-shadow {
    box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.3) !important;
  }
  .planner{
    opacity: 0.5 !important;
    cursor: not-allowed !important;
  }