#idleTimerModal{
    &.modal-dialog.slide-down {
      animation: slideDown 4s ease-out;
    }
    &.modal-dialog{
      max-width: none !important;
      width: 612px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 90vh;
      position: relative;
    }
  
    @keyframes slideDown {
      from {
        transform: translateY(-100vh);
      }
      to {
        transform: translateY(0);
      }
    }
    
    .modal-content{
      border: 0px;
      border-radius: 10.5px;
    }
    .modal-header{
      background-color: #FFFFFF;
      margin: 15px;
      margin-bottom: 0px;
      border: 0px;
    }
    .modal-body{
      margin: 15px;
      margin-top: 0px;
      font-family: Inter;
    }
    .btn-close{
      color: #939393;
      font-size: 14px;
      font-weight: 600;
    }
    .idleTimerClock{
      width: 120px;
      height: 120px;
    }
    .idleTimerContent h1{
      font-size: 18px;
      font-weight: 700;
      color: #1A1A1A;
      padding: 10px;
    }
    .idleTimerContent p{
      font-size: 15px;
      font-weight: 400;
      color: #606060;
      padding: 10px;
    }
    .idleTimerSession h3{
      font-size: 15px;
      font-weight: 600;
      color: #000000;
      padding: 10px;
    }
    .idleTimerSession h2{
      font-size: 20px;
      font-weight: 700;
      color: #720C20;
      padding: 10px;
    }
    .idleTimerButton button {
      border: 1px solid #d9dee6;
      background-color: #ffffff;
      font-size: 15px;
      font-weight: 400;
      color: #000000;
      padding: 12px 56px;
      border-radius: 8px;
      cursor: pointer;
      transition: background-color 0.3s, border 0.3s, color 0.3s;
    }
    .idleTimerButton button.active,
    .idleTimerButton button:hover {
        border: 0px;
        background-color: #720c20;
        font-size: 15px;
        font-weight: 400;
        color: #ffffff;
        padding: 12px 56px;
        border-radius: 8px;
    }
    .idleTimerButton .row {
        padding-top: 15px;
    }
  }