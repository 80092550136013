
@media (max-width: 480px){
    .mobile{
        width: 100% !important;
        margin-top: 1rem !important;
        margin-left: 0px !important;
    }
    .mobileLocate{
        display: block !important;
        margin-bottom: 0.5rem !important;
    }
    .mobileText1{
        font-size: 0.9rem !important;
        margin-left: 0 !important;
    }
    .mobileText12{
        font-size: 1.1rem !important;
    }
    .mobileLocateHome{
        display: block !important;
        height: fit-content !important;
    }
    .mobileLocateBtns{
        margin-left: 0.5vh !important;
        margin-right: 0.5vh !important;
    }
    .mobileToggle1{
        margin-right: 0 !important;
    }
    .mobileDiv{
        width: 100% !important;

    }
  }
  
  @media only screen and (max-width: 767px) and (min-width: 481px){
    .mobile{
        /* width: 100% !important; */
        margin-top: 1rem;
        margin-left: 0px !important;
        
    }
    .mobileLocate{
        display: flex !important;
        margin-bottom: 0.5rem !important;
    }
    .mobileText1{
        font-size: 0.9rem !important;
    }
    .mobileText12{
        font-size: 1.1rem;
    }
    .mobileLocateHome{
        display: block !important;
        height: fit-content !important;
    }
  }
  /* @media (min-width: 2560px) {
  
    .Export-to-Excel-large {
      
            margin-right: -142% !important;
    }
   }  */