.ClassReg-Tag-h4{
    font-weight: 400;;
}
.main-row-readMore{
    border: 1px dashed #000000;
    margin-right: 10px;
    margin-right: auto;
    margin-left: auto;
}
.Topic-tag-h3{
    color: #B06A0A;
    font-weight: 700;
}
.Topic-tag-span{
    color: black;
    font-weight: 600;
    font-size: 20px;
}
.Book-Now-Button{
    border: 1px solid #720C20;
    color: #720C20;
    width: 140px;
}
.h6-color-tag{
    color: #595959
}
/* .Class-Img-Div{
    height: 130px;
} */
.desc-Tag-h6{
    font-weight: 400;
    color: #595959;
    margin-left: 5px;
}
.desc-Tag-span{
    color: #720C20;
    font-weight: 500;
}
/* .Class-Img-prof-Div{
    height: 160px;
} */
.about-classroom-tag-h6{
    color: #720C20;
    font-size: 20px;
    font-weight: 500;
}
.desc-classroom-tag-h6{
    font-weight: 400;
    color: #595959;
}
.class-img-div-show{
    display: none;
}
.Book-Now-Button-Div {
    display: none !important;
}

@media (max-width:767px ){
    .class-img-div-show{
        display: block;
    }
    .Class-Img-Div{
        height: 130px;
        display: none;
    }
   
    .Book-Now-Button-Div-show{
        display: none !important;
    }
    .Book-Now-Button-Div {
        display: block !important;
        width: 100%;
    }

    .Book-Now-Button{
        width: 25%;
    }
}

@media (max-width:600px ){

    .class-img-div-show{
        display: block;
    }
    .Class-Img-Div{
        height: 130px;
        display: none;
    }
   
    .Book-Now-Button-Div-show{
        display: none !important;
    }
    .Book-Now-Button-Div {
        display: block !important;
        width: 100%;
    }
    .Book-Now-Button{
        width: 100%;
    }
}
.dev{
    width: auto;
    margin-right: auto;
}