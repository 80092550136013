h1 {color: brown;}
.CongratulationHeading{
  color: #D79B48;
  font-family: Georgia, 'Times New Roman', Times, serif;
}
.Congratulation-box{
   height: 150px;
   display: flex;
   border: 1px solid #720C20;
   border-radius: 30px;
 }

 .Congratulation-box img{
   width: 90%;
   min-width: 50px;
   max-width: 150px;
 }
 .vatical-line{ border-left: 2px solid #666666; height: 50px;}
     .conOne{ color: #666666; } 
 .CongratulationText  { float: right;   font-size: medium; padding-right: 30%;}
    .center-planner{ height: 400px;}
  .CongratulationHeader{color: #720C20; float: right;
 font-size: 22px; margin-left: 20%;
 font-family: sans-serif;
 padding-right: 8%;}
 .CongratulationMain{ float: right;}
 .Congratulationone{
    font-size: 24px;
    font-family: sans-serif;
     padding-left:10%;
    
     
  }
  .Congratulations-screens-msg{
    height: calc(100vh - 140px) !important;
     overflow: auto !important;
  }
  @media (min-width: 2560px) {
   .CongratulationHeading{
    font-size: 60px !important;
    margin-top: 50% !important;

   }
}
  
