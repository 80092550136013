
.footer{ 
width: 100%;

background-color: #F6F6F6;
text-align: end;}
.footer img{float: right; padding-bottom: 20px; padding-right: 4%;
}
.footer h6{ padding-bottom: 10px; font-size: 24px;
   padding-right: 4%;


color:#c4b9b9;}
.main-footer-one{float: left;
text-align: start; color: #820024;
font-size: 14px;
font-family: 'Open Sans', sans-serif;
text-decoration: solid;}
.main-footer-two{
text-align: end;
}
.bgclr1{
  background-color: #F6F6F6;
}

