
 .SampleData1{
   --bs-modal-width : 40.5rem;
  
   
}
.publish-main-div{
   height: calc(100vh - 140px) !important;
   
   overflow: auto !important;
  }
  .after-payment-container {
   height: calc(100vh - 220px) !important;
   
   overflow: auto !important;
  }
@media (max-width: 600px) {
    .new{
        width: 100% !important;}
     .preview{
        height: 100%;
        width: 100%;

     }
    
 }
/* @media (min-width: 600px) {
    .new{
        width: 100% !important;}
     .preview{
        height: 100%;
        width: 100%;

     }
    
 } */
 
 .name{
    align-items: center !important;
   
    
 }
 .new{
    width: 35%;

 }
 .vertical-scroll::-webkit-scrollbar{
    width: 10px;
    height: 8px !important;
    margin-right: 2rem !important;
   
    
}
.vertical-scroll::-webkit-scrollbar-thumb{
   background: rgb(114,12,32) !important;
    border-radius: 100vw;
    margin-right: 2rem !important;
}
.react-calendar__tile--active {
  background: rgb(114,12,32) !important;
  color: white;
}
.vertical-scroll::-webkit-scrollbar-track{
    background: #c4c4c4 !important;
    /* background: rgb(114,12,32) !important ; */
    border-radius: 100vw;
    margin-block:10px;
    margin-right: 2rem !important;

}
@media (min-width: 2560px) {
   .congratulationNew{
      font-size: 30px;
   }
   .New-Registration-large{
      margin-right: -210px  !important;
   }
  } 

.my-calendar {

   margin: 20px;
 }
 
 /* .my-calendar button {
   padding: 10px 20px;
   background-color: #eaeaea;
   border: none;
   border-radius: 4px;
   color: #333;
   font-weight: bold;
   cursor: pointer;
 } */
 .TimeSlot{
  font-weight: 600;
  font-size: 18px;
  color: #720c20;
 }

 .my-calendar button:hover {
   background-color: #ddd;
 }
 
 .my-calendar .calendar {
   /* position: absolute; */
   margin-left: auto !important;
   margin-right: auto !important;
   top: 40px;
   left: 0;
   z-index: 10;
   background-color: #fff;
   border: 1px solid #ccc;
   border-radius: 4px;
   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
   padding: 10px;
 }
 
 .my-calendar .calendar .calendar-day {
   display: inline-block;
   width: 30px;
   height: 30px;
   text-align: center;
   line-height: 30px;
   border-radius: 50%;
   cursor: pointer;
 }
 
 .my-calendar .calendar .calendar-day:hover {
   background-color: #eee;
 }
 
 .my-calendar .calendar .current-day {
   background-color: #333;
   color: #fff;
 }
 
 .my-calendar .calendar .calendar-month {
   font-weight: bold;
   margin-bottom: 10px;
 }
 
 .my-calendar .calendar .calendar-weekdays {
   margin-bottom: 10px;
 }
 
 .my-calendar .calendar .calendar-weekday {
   display: inline-block;
   width: 30px;
   text-align: center;
 }
 
 .my-calendar .calendar .calendar-weekday:first-child {
   color: #f00;
 }
 
 .my-calendar .calendar .calendar-weekday:last-child {
   color: rgb(114,12,32) !important;;
 }
 .react-calendar {
   width: 36rem !important;
   max-width: 100%;
   background: white;
   border: 1px solid #a0a096;
   font-family: Arial, Helvetica, sans-serif;
   line-height: 1.125em;
}
 
abbr:not([title]){
   display: none;
}
/* .date-container:hover .dropdown {
   display: block;
 } */
 
 .newDropdown {
   position: absolute !important;
   background-color: #ede9e9 !important;
   width: fit-content !important;
   height: 150px !important;
   z-index: 1000;
   color: #333 !important;
   transform: translate(-150%,-25%);
   /* Add more positioning properties as needed */
 }
 .react-calendar__tile .react-calendar__tile--now .react-calendar__month-view__days__day{
   overflow: none;
 }
 /* .dropdown:hover>.dropdown-menu {
  display: block;
} */

/* .dropdown>.dropdown-toggle:active { */
  /*Without this, clicking will make it sticky*/
    /* pointer-events: none; */
/* }// */
.ulData{
   background-color: white !important;
   color: #333 !important;
   border: 1px dashed !important;
}
/* .react-calendar__tile--now {
  background-color: white;
} */

.seletectedIndexBackground{
  background-color: #F0F0F0;
} 

.selected-border{
  margin-bottom: 10px;
  background-color:#F0F0F0 ;
  border: 2px solid #720c20 !important;
  border-radius: 7px;
  padding: 8px 10px ;

}
.selected-border p {
  margin-bottom: 5px;
  color: #720c20;
  font-weight: 600;
}
.selected-border h5 {
  color: #720c20;
  font-weight: 600;
}
.estimatePlaning{
  width: 91.5%;
  border-radius: 7px;
}

input[type="checkbox"]:checked{
  accent-color: #720c20 !important ;
  background-color: #720c20 !important;
}